//
//	Chat
//
.chat-container {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	height: 74vh;
	overflow-y: scroll;
}

.chat-send {
	align-self: flex-end;
	width: fit-content;
	max-width: 700px;
	padding: 14px 18px;
	margin: 6px 8px;
	background-color: #5b5377;
	border-radius: 16px 16px 0 16px;
	border: 1px solid #443f56;
	text-align: right;

	P {
		margin: 0;
	}
}
.chat-received {
	align-self: start;
	min-width: fit-content;
	max-width: 700px;
	padding: 14px 18px;
	margin: 6px 8px;
	background-color: #6c8ea4;
	border-radius: 16px 16px 16px 0;
	border: 1px solid #54788e;
	text-align: left;
}

.chat-group {
	display: flex;
	width: 100%;
	margin-bottom: $spacer * 0.75;

	> * {
		margin-right: $spacer * 0.25;
	}
}

.chat-avatar {
	display: flex;
	align-items: flex-end;
}

.chat-messages {
	max-width: 60%;
}

.chat-message {
	@include dark-mode {
		background-color: $gray-800;
	}
	@include border-radius($border-radius * 1.5);

	padding: $spacer;
	background-color: $gray-200;

	&:not(:last-child) {
		margin-bottom: $spacer * 0.25;
	}

	&:not(.chat-message-reply) {
		&:not(:first-of-type) {
			@include border-top-start-radius($border-radius * 0.5);
		}

		&:not(:last-of-type) {
			@include border-bottom-start-radius($border-radius * 0.5);
		}
	}

	&.chat-message-reply {
		&:not(:first-of-type) {
			@include border-top-end-radius($border-radius * 0.5);
		}

		&:not(:last-of-type) {
			@include border-bottom-end-radius($border-radius * 0.5);
		}
	}
}

.chat-message-reply {
	@include dark-mode {
		background-color: rgba-to-rgb(rgba($info, 0.3), $dark);
	}

	background-color: tint-color($info, 20%);
	color: $white;
}

.chat-group-reply {
	justify-content: flex-end;
}

// .chat-send-message {}
