.half,
.half .container > .row {
	height: 100vh;
	min-height: 700px;
}

@media (max-width: 991.98px) {
	.half .bg {
		height: 200px;
	}
}

.half .contents {
	background: #f6f7fc;
}

.half .contents,
.half .bg {
	width: 50%;
}
@media (max-width: 1199.98px) {
	.half .contents,
	.half .bg {
		width: 100%;
	}
}
.half .contents .form-control,
.half .bg .form-control {
	border: none;
	-webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
	border-radius: 4px;
	height: 54px;
	background: #fff;
}
.half .contents .form-control:active,
.half .contents .form-control:focus,
.half .bg .form-control:active,
.half .bg .form-control:focus {
	outline: none;
	-webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
}

.half .bg {
	background-size: cover;
	background-position: center;
}

.half a {
	color: #888;
	text-decoration: underline;
}

.half .btn.btn-auth {
	padding: 15px 35px;
}

.half .otp-button {
	display: block !important;
}

.half .otp-input {
	height: 70px !important;
	font-size: 24px;
}

.half .forgot-pass {
	position: relative;
	top: 2px;
	font-size: 14px;
}
.half .otp-label {
	cursor: pointer;
}
.custom-check {
	font-size: 16px !important;
}

// .right-inner-addon {
// 	position: relative;
// }
// .right-inner-addon input {
// 	padding-right: 35px !important;
// }
// .right-inner-addon svg {
// 	position: absolute;
// 	right: 10px;
// 	pointer-events: none;
// 	top: 25%;
// 	font-size: 24px;
// 	margin: auto;
// }
.made-with-love {
	bottom: 3rem !important;
	span {
		color: #666;
		font-size: 12px;
		display: block;
		position: absolute;
		bottom: 10px;
		position: absolute;
		width: 90%;
		left: 50%;
		top: 45%;
		bottom: auto;
		right: auto;
		transform: translateX(-50%) translateY(-50%);
		text-align: center;
		
		a {
			color: #000000;
			text-decoration: none;
		}
	}
}
.auth-footer {
	position: fixed;
	left: 0;
	bottom: 0;
	width: 50%;
	text-align: center;
}
.signup-agreement {
	bottom: 18rem !important;
}
