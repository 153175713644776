//
// Custom
//

@use 'sass:map';
$heart-color: #ff8299;

.text-ovonts {
	color: #ed3b5b !important;
}

.scroll-margin {
	scroll-margin-top: map.get($header, height) + map.get($subheader, height) + map.get($page, padding-y);
}

.sticky-top-size {
	top: map.get($header, height) + map.get($subheader, height) + map.get($page, padding-y);
}

.border-transparent {
	--#{$prefix}border-opacity: 0;
}

// .quill {
// 	.ql-toolbar:first-child {
// 		display: none !important;
// 	}
// 	.ql-container {
// 		min-height: 25rem;
// 		height: 100%;
// 		flex: 1;
// 		display: flex;
// 		flex-direction: column;
// 	}
// 	.ql-editor {
// 		height: 100%;
// 		flex: 1;
// 		overflow-y: auto;
// 		width: 100%;
// 	}
// }

.react-confirm-alert-button-group>button {
	padding: 13px 18px !important;
}

.dashboard_wrapper {

	.card_content_library,
	.card_influencer_status,
	.card_activity_feed,
	.card_news_feed {
		height: 40rem !important;
	}
}

.aside-body {
	overflow: visible;
}

.modern-design .aside {
	overflow: visible !important;
}


.card_partner_table {
	height: 20rem;
}

.content_management_wrapper {
	.card_content_library {
		height: 38rem !important;
	}
}

.irm_wrapper {
	.card_activity_feed {
		height: 40rem;
	}
}

.campaign_wrapper {
	.card_content_library {
		height: 38rem !important;
	}

	.card_activity_feed {
		height: 40rem;
	}
}

.card_quick_search {
	.card-header {
		background-color: #ffe8ef !important;
	}
}

.div-custom-radio-button {
	display: flex;
	justify-content: center;
	align-content: center;
	flex-wrap: wrap;

	.radio-pillbox {
		width: 100%;
		height: 2.8rem;
		background-color: #f8f9fa;
		background-clip: padding-box;
		border: 1px solid #f8f9fa;
		box-shadow: inset 0 1px 2px rgb(0 0 0 / 8%);
		border-radius: 1rem;
		overflow: hidden;

		.radiogroup {
			height: 100%;
			width: 100%;
			display: flex;

			div {
				width: 100%;
				position: relative;

				input {
					-webkit-appearance: inherit;
					width: 100%;
					height: 100%;
					transition: background 300ms ease-out;
					margin: 0;
					outline: 0;

					&:checked+label {
						box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
						background-color: #e692ad !important;
						border-color: #e692ad !important;
						color: #ffffff !important;
					}

					&:hover+label {
						color: #1f2128;
						background-color: #e7eef8;
						border-color: #e7eef8;
					}

					+label {
						position: absolute;
						top: 0rem;
						left: 0;
						width: 100%;
						color: #7f8388;
						height: 100%;
						font-weight: 600;
						text-align: center;
						display: flex;
						align-items: center;
						justify-content: center;
						cursor: pointer !important;
					}
				}
			}
		}
	}
}

.custom-confirm-alert-overlay {
	z-index: 999999 !important;
}

.dv-list-check-group {
	height: 10rem;
	overflow-y: scroll;
}

.custom-badge {
	display: inline-block;
	padding: 0 10px;
	line-height: 25px;
	border-radius: 25px;
	color: #fff;
	background-color: #f1f1f1;

	.btnclose {
		padding-left: 5px;
		color: #fff;
		float: right;
		cursor: pointer;
	}
}

.btn_heart {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 35px;
	height: 35px;
	// border-radius: 50%;
	background: none;
	border-style: initial;
	// border: 1px solid rgba(0, 0, 0, 0.15);
	color: #ff3d60;
	//color: $heart-color;
	cursor: pointer;
	transition: all 0.5s ease;

	&:focus {
		outline: none;
	}

	&:hover {
		box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.15);
	}

	&.liked {
		border-color: $heart-color;
		animation: shadow-grow 2s;

		svg {
			animation: heart-grow 0.7s;
		}

		.heart {
			fill: $heart-color;
		}
	}

	svg {
		width: 20px;
		height: auto;
		// transition: all 2s ease;

		&:hover {
			width: 40px !important;
		}

		.heart {
			fill: rgba(0, 0, 0, 0.15);
		}

		.shine {
			fill: #ffffff;
		}
	}
}

@keyframes shadow-grow {
	0% {
		box-shadow: 0 0;
	}

	50% {
		box-shadow: 0 0 5px 20px rgba(255, 255, 255, 0);
	}

	100% {
		box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
	}
}

@keyframes heart-grow {
	0% {
		transform: scale(3);
		opacity: 0;
	}

	50% {
		opacity: 1;
	}

	100% {
		transform: scale(1);
	}
}

.infulencer_details {
	.infulencer_stats {
		.icon {
			font-size: 16px;
		}

		span {
			font-size: 14px;
		}
	}
}

.btn_create_workspace {
	display: flex;
	gap: 12px;
	padding: 16px;
	// border: 2px solid rgb(44, 48, 53);
	border-radius: 10px;
	background: none;
	text-align: left;
	cursor: pointer;

	// &:hover {
	// 	background: rgb(28, 31, 34);
	// 	border: 2px solid rgb(72, 77, 81);
	// }
	.btn_create_workspace__icon {
		display: flex;
		flex-shrink: 0;
		-webkit-box-align: center;
		align-items: center;
		-webkit-box-pack: center;
		justify-content: center;
		border-radius: 10px;
		// background: rgb(37, 40, 45);
		width: 36px;
		height: 36px;
		// color: rgb(227, 229, 232);
	}

	.btn_create_workspace__text {
		font-size: 1rem;
		line-height: 1.125;
		margin-bottom: 4px;
		font-weight: 500;
		// color: rgb(227, 229, 232);
	}

	.btn_create_workspace__sub_text {
		font-size: 0.9375rem;
		line-height: 1.26667;
		margin: 0px;
		// color: rgb(147, 153, 159);
	}
}

.project_height {
	.carousel-inner {
		height: 20rem;
	}

	.d-block {
		height: 100%;
		width: 100%;
	}
}

.react-confirm-alert-body {
	h1 {
		font-size: 2rem !important;
	}
}

.auth-overlay {
	position: fixed;
	width: 100%;
	max-width: 250px;
	min-height: 250px;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 2;
	cursor: pointer;
}

.options-align-right {
    width: 24px;
    margin-top: 118px;
    margin-right: 1394px;
    position: absolute;
    top: 0;
    right: 0;
}
