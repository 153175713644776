$primary-font-color: #909191;
$primary-background-color: #ffffff;
$border-style: 1px solid #ddd;
$border-radius: 20px;

.dropdown {
	position: relative;

	.list {
		position: absolute;
		background-color: #fff;
		width: 100%;
		box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
		z-index: 1;
		max-height: 40vh;
		overflow-y: scroll;
		border-radius: $border-radius;

		.dropdown_item {
			padding: 25px;
			width: 100%;
			border-radius: $border-radius;
			&:hover {
				cursor: pointer;
			}
		}
	}

	.selected_creator {
		margin: 10px;
		display: grid;
		grid-template-columns: 1fr 1fr;

		.creator {
			border-radius: 10px;
			padding: 8px;
			background-color: $primary-background-color;
			color: $primary-font-color;
			border: $border-style;
			display: flex;
			justify-content: space-around;
			align-self: center;

			.detail {
			}

			.cross {
				cursor: pointer;
			}
		}
	}
}
